import React from 'react';
import { Link } from 'gatsby';

import {useStaticQuery, graphql} from 'gatsby'

import styled from 'styled-components'

import { Grid } from '@material-ui/core';
import LocationCityIcon from '@material-ui/icons/LocationCity';

import SofaIcon from '@material-ui/icons/Weekend';

import Section from '../../utils/section'
import Flipper from '../../utils/flipper';
import Button from '../../utils/button';

const bradius = "8px";


const Panel = styled.div` 
  border-radius: ${bradius};
  box-shadow: 0 1.5rem 4rem rgba(0,0,0,0.15);
  height: 100%;
  min-width: 250px;
`

const FPanel = styled(Panel)`
  background-color: #fff;
  position: relative;

  .cardFooter {
    color: ${props => props.theme.colors.dark};
    background-color: #fff;
    padding: 2rem;
    
  }
`
const BgImage = styled.div`
  background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url(${props => props.image.src});
  background-size: cover;
  background-position: center;

  height: 23rem;
  border-top-left-radius: ${bradius};
  border-top-right-radius: ${bradius};

  h6 {
      padding-top: 9rem;
      font-size: 2.3rem;
      width: 100%;
      text-align: center;
      color: #fff;
    }
`

const FIcon = styled.div`
  position: absolute;
  top: 48%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 10rem;
  width: 10rem;
  border-radius: 50%;
  /* border: 2rem solid #fff; */
  background-color: #fff;
  text-align: center;

  .icon-sofa {
    margin-top: 2rem;
    font-size: 5.5rem;
    color: ${props => props.theme.colors.primary};
  }

  @media ${props => props.theme.breakpoints.down('md')} {
    top: 54%;
  }
`;

const Offer = styled.div`
  width: 85%;
  font-size: 90%;
  margin: 0 auto;
  margin-top: 4rem;
  text-align: center;
`

const Price = styled.div`
  text-align: center;
  font-size: 2.2rem;
  margin-top: 3.5rem;
  /* font-family: "Suez One"; */
  font-weight: 400;
`

const Hover = styled.p`
  position: absolute;
  font-size: 1.3rem;
  color: ${props => props.theme.colors.primary};
  bottom: 2rem;
  left: 50%;
  transform: translateX(-50%);

  @media ${props => props.theme.breakpoints.down('md')}, only screen and (hover: none) {
    display: none;
  }
`

const BPanel = styled(Panel)`
  background: linear-gradient(145deg, #464646, #1d1d1d 50%);
  /* background-color: ${props => props.theme.colors.dark}; */
  padding: 4rem 1rem;
  /* display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column; */
  text-align: center;
  color: #fff;

  .icon-back {
      color: ${props => props.theme.colors.primary};
      font-size: 400%;
      margin-bottom: 2rem;
    }

    .list-header {
      font-size: 2.3rem;
      width: 100%;
      text-align: center;
      color: #fff;
    }

    @media ${props => props.theme.breakpoints.down('md')}, only screen and (hover: none) {
      clip-path: polygon(0 15%, 100% 0, 100% 100%, 0% 100%);
      padding: 7rem 4rem 4rem 4rem;
      border-top-right-radius: 0 !important;

      .icon-back {
        display: none;
      }
  }
`
const OfferItems = styled.ul`
  margin-bottom: 5rem;
  margin-top: 1rem;
  li {
    display: block;
    margin: 0 auto;
    padding: 1rem;
    padding-top: 1.5rem;
    width: 50%;
    border-bottom: 1px solid #fff;
  }
`

const CardButton = styled(Button)`
  position: absolute;
  bottom: 4rem;
  left: 23%;
  /* transform: translateX(-50%); */
  background-color: #fff;
  color: ${props => props.theme.colors.dark};
  padding: 1.5rem 4rem;

  &:hover {
    transform: translateY(-3px);
      box-shadow: 0 0.5rem 2rem rgba(0,0,0,0.1);
  }

  &:active {
    transform: translateY(-1px);
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.2);
  }
`

const Front = ({image, header, offer, price}) => (
  <FPanel>
    <BgImage image={image}>
      <h6>{header}</h6>
    </BgImage>
    <div className="cardFooter">
      <FIcon>
        <SofaIcon className="icon-sofa" />
      </FIcon>
      <Offer>
      {offer}
      </Offer>
      <Price>
       {price}
      </Price>
      <Hover>HOVER OVER ME</Hover>
    </div>
  </FPanel>
)

const Back = ({Icon, header, list, path="/"}) => (
  <BPanel>
    <Icon className="icon-back" />
    <h6 className="list-header">
    {header}
    </h6>
    <OfferItems>
    {list.map(li => <li key={li.item}>{li.item}</li>)}
    </OfferItems>
    <CardButton>
      <Link to="/gallery">View Gallery</Link> 
    </CardButton>
  </BPanel>
)

const ViewGallery = ({ children }) => (
  <Flipper
    className="view-gallery"
    front={children[0]}
    back={children[1]}
  />
)

export default function Explore() {

  const data = useStaticQuery(graphql`
    query ExploreQuery {
      strapiExploreHeader {
        title
        content
      }
      allStrapiExploreCard {
        edges {
          node {
            header
            image {
              childImageSharp {
                fixed(width: 400, quality: 100) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
            offer
            price
            list {
              item
            }
          }
        }
      }
    }
    `)

    const { title, content } = data.strapiExploreHeader; 
    const { edges } = data.allStrapiExploreCard;

    return (
      <div className="explore">
      <Section
        header={title}
        description={content}
      >
          <Grid justify="center" container spacing={1}>
          <Grid item xs={false} md={1}></Grid>
          {edges.map(({node}, i) =>
            <Grid key={node.header} item xs={12} md={5}>
              <ViewGallery>
                <Front 
                  image={node.image.childImageSharp.fixed}
                  header={node.header}
                  list={node.list}
                  offer={node.offer}
                  price={node.price}
                />
                <Back 
                  Icon={LocationCityIcon}
                  header={node.header}
                  list={node.list}
                />
              </ViewGallery>
            </Grid>
            )}
            <Grid item xs={false} md={1}></Grid>
          </Grid>
      </Section>
      </div>
    )
}
import React from "react"
import {useStaticQuery, graphql} from 'gatsby'
import styled from 'styled-components'

import CommentIcon from '@material-ui/icons/Comment';

import Button from '../../utils/button';

const barHeight = "80px";

const Section = styled.section`
  background-image: url(${props => props.image.src});
  background-position: center;
  background-size: cover;
  height: 500px;
  position: relative;
`

const Overlay = styled.div`
  position: absolute;
  top: 0;
  width: 60%;
  height: 100%;
  clip-path: polygon(0 0, 100% 0, 70% 100%, 0 100%);

  .form-background {
    height: 100%;
    opacity: 0.6;
    background-color: #fff;
  }

  @media  ${props => props.theme.breakpoints.down('md')} {
      width: 100%;
      clip-path: none;

      .form-background {
        background-color: ${props => props.theme.colors.dark};
        opacity: 0.5;
      }
  }
`

const Bar = styled.div`
  width: 100%;
  height: ${barHeight};
  background-color: ${props => props.theme.colors.dark};
  z-index: 1;
`

const FormContainer = styled.div`
  position: absolute;
  height: 100%;
  top: 50%;
  transform: translate(0, -50%);
  margin-left: 10%;

  form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    height: calc(100% - 80px);

  .fields {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    height: 320px;
  }
  }
`

const Header = styled.h5`
  color: ${props => props.theme.colors.body};
  height: ${barHeight};
  line-height: ${barHeight};

.icon {
    color: ${props => props.theme.colors.primary};
    font-size: 3rem;
    vertical-align: middle;
    margin-right: 20px;
  }
`

const Input = styled.input`
  border-radius: 3px;
  background-color: #fff;
  border:none;
  background-image:none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  width: 300px;
  height: 40px;
  font-size: 1.3rem;
  padding-left: 20px;
  line-height: 40px;
  transition: all 0.2s;

  &:focus {
    box-shadow: inset 5px 3px 5px -5px ${props => props.theme.colors.primary};
  }
`

const Textarea = styled(Input)`
  height: 100px;
`

const FormButton = styled(Button)`
  margin-top: 10px;
  padding: 1rem 2.5rem;
  text-transform: uppercase;
  font-size: 1.2rem;
  align-self: flex-start;
`

export default function Contact() {

  const data = useStaticQuery(graphql`
    query ContactQuery {
      strapiContact {
        background {
          childImageSharp {
            fluid(maxWidth: 3000, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  `)

  const fluidBg = data.strapiContact.background.childImageSharp.fluid

  return (
    <Section image={fluidBg} id="contact">
      <Overlay>
        <Bar />
        <div className="form-background" />
        <FormContainer>
          <Header><CommentIcon className='icon pulse' />Let's Talk</Header>
          <form name="contact" method="POST" data-netlify="true" data-netlify-honeypot="bot-field">
            <div className="fields">
              <Input placeholder="Name" name="name" required />
              <input type="hidden" name="form-name" value="contact" />
              <Input placeholder="Email" type="email" name="email" required />
              <Textarea as="textarea" placeholder="Description" name="description" required></Textarea>
              <FormButton>Submit</FormButton>
            </div>
          </form>
        </FormContainer>
      </Overlay>
    </Section>
  )
}
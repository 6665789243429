import React from 'react'
import { useStaticQuery, graphql } from "gatsby"
import styled from 'styled-components'

const imageHeight = '500px';

const Section = styled.section`
  position: relative;
`
const Text = styled.div`
  background-color: ${props => props.theme.colors.dark};
  color: ${props => props.theme.colors.body};
  padding: 8%;
  display: none;
  

  @media ${props => props.theme.breakpoints.up('md')} {
    position: absolute;
    right: 0;
    width: 50%;
    clip-path: polygon(25% 0, 100% 0, 100% 100%, 0 100%);
    opacity: 0.95;
    padding: 0 3% 0 0 !important;
    height: ${imageHeight};

    &:before {
      content: ' ';
      float: left;
      shape-outside: polygon(0 0, 32% 0, 5% 100%, 0% 100%);
      height: 100%;
      width: 100%;
    }
  }
`
const Header = styled.h4`
  color: ${props => props.theme.colors.primary};
  margin-bottom: 30px;

  @media ${props => props.theme.breakpoints.up('md')} {
    margin-top: 60px;
  }
`
const Content = styled.div`
  p {
    text-align: justify;
    line-height: 150%;
    margin-bottom: 20px;
  }
`

const Image = styled.div`
  background-image: url(${props => props.image.src});
  background-size: cover;
  background-position: center;
  height: ${imageHeight};
`

export default function Stock() {

  const data = useStaticQuery(graphql`
    query StockQuery {
      strapiStock {
        title
        content
        image {
          childImageSharp {
            fluid(maxWidth: 3000, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  `)

  const { title, content, image} = data.strapiStock;

  return (
    <div className="stock">
    <Section>
        <Text className="stock-text">
          <Header>{title}</Header>
          <Content dangerouslySetInnerHTML={{__html: content}}></Content>
        </Text>
        <Image image={image.childImageSharp.fluid} />
    </Section>
    </div>
  )
}


import React from 'react'
import styled from 'styled-components'
import RowWrapper from './rowWrapper'

const StyledSection = styled.section`
  padding: 60px 0;
  overflow: hidden;
`

const Header = styled.h5`
  margin-bottom: 30px;

  &:after {
    display: block;
    height: 2px;
    background-color: ${props => props.theme.colors.primary};
    content: " ";
    width: 100px;
    margin: 0 auto;
    margin-top: 30px;
  }
`

const Description = styled.p`
  width: 70%;
  line-height: 170%;
  margin-bottom: 70px;

  @media ${props => props.theme.breakpoints.down('md')} {
    text-align: justify;
    font-size: 90%;
  }
`

export default function Section({header, description, children, className}) {
  return (
    <StyledSection className={className}>
      <RowWrapper centered>
        <Header>{header}</Header>
        <Description>{description}</Description>
        {children}
      </RowWrapper>
    </StyledSection>
  )
}

import React from 'react';

import StarIcon from '@material-ui/icons/Star';
import Avatar from "../images/user.png"

import styled from 'styled-components';


const Card = styled.div`
  height: 280px;
  width: 450px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.3);
  background: linear-gradient(to bottom right, #fff, #efefef);
  border-radius: 8px;
  border-top: 4rem solid ${props => props.theme.colors.dark};
  /* text-align: center; */

  @media ${props => props.theme.breakpoints.down('sm')} {
    width: 38rem;
    height: 26rem;
  }

  @media ${props => props.theme.breakpoints.down('xs')} {
    width: 35rem;
    height: 22rem;
  }
`

const Header = styled.div`
  width: 30rem;
  margin: 4rem auto 4rem auto;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;

  .avatar {
  width: 70px;
  height: 70px;
  margin-right: 1rem;
  }

  @media ${props => props.theme.breakpoints.down('xs')} {
    margin: 2rem auto 1rem auto;
  }
`

const Rating = styled.div`
margin-bottom: .5rem;
  .star {
    font-size: 3rem;
    padding-right: 2px;
    color: ${props => props.theme.colors.primary};
  }
`

// have to decide about font-size and styling
const Quote = styled.blockquote`
  width: 32rem;
  margin: 0 auto;
  text-align: center;
  font-size: 1.6rem; 
  margin-bottom: 20px;
  quotes: "“" "”";
  line-height: 1.4;
  font-family: "century gothic";
  font-weight: normal;
  /* font-style: italic; */

  &:before {
    content: open-quote;
    margin-right: .5rem;
    left: 0;
    top: 10px;
  }

  &:after {
    content: close-quote;
    margin-left: .5rem;
    top: 10px;
  }

  &:before, &:after {
    display: inline;
    line-height: 0;
    position: relative;
    color: #999;
    font-size: 1.8em;
    font-family: 'Times New Roman', Times, serif;
  }
`

const Signature = styled.p`
  font-size: 2rem;
  font-weight: 400;
  padding-left: .4rem;
`

const stars = [];

for (let i = 0; i < 5; i++){
  stars.push(<StarIcon className="star" />)
}

export default function TestimonialCard({ text, signature }) {
  return (
    <Card>
      <Header>
      <img src={Avatar} className="avatar" alt="avatar" />
      <div className="info">
      <Rating>
      {stars}
      </Rating>
      <Signature>{signature}</Signature>
      </div>
      </Header>
      <Quote>{text}</Quote>
    </Card>
  )
}
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import ScrollToPlugin from 'gsap/src/ScrollToPlugin';
import DrawSVGPlugin from 'gsap/DrawSVGPlugin'
gsap.registerPlugin(ScrollToPlugin, ScrollTrigger, DrawSVGPlugin);

const noScroll = () => {
  window.scrollTo(0, 0);
}

const intoView = ( trigger, start, ta ) => {
  const tl = gsap.timeline({
    scrollTrigger: {
      trigger: trigger,
      start: start,
      toggleActions: ta
    }
  })
  return tl;
}

const master = gsap.timeline();


const startSlide = () => {

  window.addEventListener('scroll', noScroll);

  const tl = gsap.timeline({
    // onComplete: () => {
    //   window.removeEventListener('scroll', noScroll);
    // } 
  });

  let x, y, scale = 1;

  const sw = window.screen.width;
  const sh = window.screen.height;
  
  let hOffset = sh / 3;
  let wOffset = sw / 2.6;

  y = hOffset;
  x = wOffset;

  if (sw >= 1280 ) { x = 1280 / 2.25; scale = 4}
  else if (sw >= 960) { scale = 4}
  else if(sw >= 600) {scale = 3.5}
   else if (sw <= 600) { scale = 3}

  //  console.log(sw, wOffset);

  tl.set('.logo', {x: x, y: y, autoAlpha: 0})
  .addLabel('start')
  .to('.logo', {autoAlpha: .8, scale: scale, duration: 1.2, delay: .5, ease: 'ease.out'}, 'start')
  .to(window, {scrollTo: 0, onComplete: () => {
    window.removeEventListener('scroll', noScroll);
  }})
  .to('.panel', {autoAlpha: 0, duration: 2, delay: 3,}, 'start')
  .to('.logo', {scale: 1, autoAlpha: 1, x: 0, y: 0, rotation: 360, duration: .7}, '-=2')
  .from('.hero-title', {x: -500, opacity: 0, duration: .8, ease: 'ease'}, '-=1.8')
  .set('.panel', {display: "none"})

  return tl;
};

const scrollers = () => {
  const tl1 = intoView('.features', 'top center', 'play none none reset');

  tl1.from('.featureCard', {duration: .7, y: 200, opacity: 0, stagger: .2, ease: 'back'})
  .to(".featureCard svg", {fill: '#bdae66', duration: .5}, "-=1");

  const tl2 = intoView('.stock', 'top 75%', 'play none none none')

  const tl3 = intoView('.explore', 'top 40%', 'play none none reverse');

  const targets = gsap.utils.toArray(".view-gallery");

  if (window.screen.width > 960) {
    tl2.set('.stock-text', {display: 'block'})
    .from('.stock-text', {x: 200, opacity: 0, duration: .8, ease: 'ease.out'});
    tl3.addLabel('start')
    .from(targets[0], {autoAlpha: 0 ,x: -300, duration: .5, ease: 'back.out'}, 'start')
    .from(targets[1], {autoAlpha: 0 ,x: 300, duration: .5, ease: 'back.out'}, 'start');
  } else {
    tl2.set('.stock-text', {display: 'block'})
    .from('.stock-text', {x: 50, opacity: 0, duration: .5, ease: 'ease.out'});
    tl3.from('.view-gallery', {y: 200, autoAlpha: 0, duration: .4, ease: 'ease.out', stagger: .2});
  }

  const tl4 = gsap.timeline();

  tl4.from('.pulse', {scale: 1.1, rotation: -5, repeat: -1, repeatDelay: 1, duration: 1})
}




export default {
  startSlide: () => master.add(startSlide()),
  animation: scrollers
}

   
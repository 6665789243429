import React, {useEffect} from 'react';
// import { Link, graphql } from "gatsby"
// import Img from "gatsby-image"
import Layout from "../components/layout"
import "../css/index.scss"
import styled from 'styled-components';

import Hero from "../components/home/hero"
import Features from "../components/home/features"
import Stock from "../components/home/stock"
import Explore from "../components/home/explore"
import Showroom from "../components/home/showrooms"
import Contact from "../components/home/contact"
import Testimonials from "../components/home/testimonials";

import animations from '../utils/tween';
import context from '../context'
import gsap from 'gsap/gsap-core';

const Panel = styled.div`
  position: fixed;
  height: 100vh;
  width: 100%;
  background-color: rgb(25, 25, 25);
  z-index: 100;
`

const IndexPage = ({ data }) => {

  useEffect(() => { // all gsap animations are declared here
    animations.animation();
    if (context.effectRan) {
      gsap.to('.logo', {autoAlpha: 1});
      document.getElementById('panel').style.display = "none";
      return
    }

    animations.startSlide();
    context.effectRan = true
  }, []);

  const businessMonroeSchema = {
    "@context": "https://schema.org",
    "@type": "Store",
    "image": [
      "https://d-wdesign.com/static/f229647552136e64cee5b295b15008a6/49aae/70a3c887e8af66b062d22bd3ed53c96f.jpg"
    ],
    "name": "D&W Design Furniture",
    "address": {
      "@type": "PostalAddress",
      "streetAddress": "1 Teveryah Way",
      "addressLocality": "Monroe",
      "addressRegion": "NY",
      "postalCode": "10950",
      "addressCountry": "US"
    },
    "geo": {
      "@type": "GeoCoordinates",
      "latitude": 41.333920923462195,
      "longitude": -74.16233942894117
    },
    "url": "https://www.d-wdesign.com/monroe-showroom",
    "telephone": "8453433366",
    "openingHoursSpecification": [
      {
        "@type": "OpeningHoursSpecification",
        "dayOfWeek": [
          "Monday",
          "Tuesday",
          "Wednesday",
          "Thursday",
          "Friday"
        ],
        "opens": "12:00",
        "closes": "18:00"
      }
    ],
    "acceptsReservations": "True"
    }

  const businessWilliSchema = {
    "@context": "https://schema.org",
    "@type": "Store",
    "image": [
      "https://d-wdesign.com/static/dc15a0f680509a4d3516a1ad0bf51c51/10d63/2b3fa90855c58418826213c94077f01c.jpg"
    ],
    "name": "D&W Design Furniture, Brooklyn Showroom",
    "address": {
      "@type": "PostalAddress",
      "streetAddress": "32 Taaffe Place",
      "addressLocality": "Brooklyn",
      "addressRegion": "NY",
      "postalCode": "11205",
      "addressCountry": "US"
    },
    "geo": {
      "@type": "GeoCoordinates",
      "latitude": 40.69783159444273, 
      "longitude": -73.96123187313123
    },
    "url": "https://www.d-wdesign.com/brooklyn-showroom",
    "telephone": "7186243366",
    "openingHoursSpecification": [
      {
        "@type": "OpeningHoursSpecification",
        "dayOfWeek": [
          "Monday",
          "Tuesday",
          "Thursday",
          "Friday"
        ],
        "opens": "12:00",
        "closes": "18:00"
      },
      {
        "@type": "OpeningHoursSpecification",
        "dayOfWeek": "Wednesday",
        "opens": "12:00",
        "closes": "20:00"
      }
    ],
    "acceptsReservations": "True"
    }

  // const {HeroImage, Heading1, Heading2} = data.strapiHero;
  return (
    <>
    <Panel id="panel" className="panel" />
  <Layout jsonld={[businessMonroeSchema, businessWilliSchema]}>
    <Hero />
    <Features />
    <Stock />
    <Explore />
    <Showroom />
    <Testimonials />
    <Contact />
    
    
    {/* <Link to="/page-2/">Go to page 2</Link> <br /> */}
  </Layout>
  </>
)
}

export default IndexPage

// export const pageQuery = graphql`
//   query IndexQuery {
//     strapiHero {
//       Heading1
//       Heading2
//       HeroImage {
//         childImageSharp {
//           fluid(maxWidth: 3000) {
//             ...GatsbyImageSharpFluid
//           }
//         }
//       }
//     }
//   }
//   `

import React from 'react'
import {useStaticQuery, graphql} from 'gatsby'
import styled from 'styled-components'
import { Grid} from '@material-ui/core'

const barHeight = "100px";

const Header = styled.h5`
  background-color: ${props => props.theme.colors.dark};
  height: ${barHeight};
  line-height: ${barHeight};
  text-align: center;
  color: ${props => props.theme.colors.body};

  @media  ${props => props.theme.breakpoints.down('md')} {
    height: 60px;
    line-height: 60px;
  }
`

const Image = styled.div`
  background-image: url(${props => props.image.src});
  background-size: cover;
  background-position: center;
  height: 500px;
  display: flex;
  align-items: flex-end;
  transition: all 0.5s;
  margin: 0;

  &:hover {
    transform: scale(1.01);
    box-shadow: 0 0 15px rgba(255, 255, 255, 0.3);
  }

  @media ${props => props.theme.breakpoints.down('md')} {
    height: 300px;
  }
`

const Address = styled.div`
  height: 30%;
  background-color: ${props => props.theme.colors.dark};
  opacity: 0.8;
  width: 100%;
  text-align: center;
  color: ${props => props.theme.colors.body};
  display: flex;
  align-items: center;
  justify-content: center;

  a {
    color: ${props => props.theme.colors.body};
    font: inherit;
  }

  p {
    font-size: 1.4rem;
    line-height: 160%;
  }
`

const Showroom = ({image, address}) => (
  <Image image={image}>
    <Address dangerouslySetInnerHTML={{__html: address}}>
      
    </Address>
  </Image>
)


export default function Showrooms() {

  const data = useStaticQuery(graphql`
    query ShowroomQuery {
      strapiShowroomHeader {
        title
      }
      allStrapiShowroom {
        edges {
          node {
            address
            image {
              childImageSharp {
                fluid(maxWidth: 3000, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  `)

  const { title } = data.strapiShowroomHeader 
  const { edges } = data.allStrapiShowroom

  // console.log(edges)

  return (
    <section style={{overflow: 'hidden'}}>
      <Header>
        {title}
      </Header>
        <Grid container>
            {edges.map(({node}, i) => 
              <Grid item xs={12} md={6} key={i}>
                <Showroom image={node.image.childImageSharp.fluid} address={node.address} />
              </Grid>
            )}
        </Grid>
   </section>
  )
}
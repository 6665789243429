import React from 'react';

import styled from 'styled-components'

const cardHeight = '50rem';
const cardWidth = '33rem;'

const Flipper = styled.div`
  position: relative;
  height: ${cardHeight};
  max-width: ${cardWidth};
  perspective: 150rem;

  &:hover .front {
    transform: rotateY(-180deg);
  }

  &:hover .back {
    transform: rotateY(0deg);
  }

  .front, .back {
    position: absolute;
    width: 100%;
    height: ${cardHeight};
    max-width: ${cardWidth};
    backface-visibility: hidden;
  }

  .back {
    transform: rotateY(180deg);
  }

  @media ${props => props.theme.breakpoints.down('md')}, only screen and (hover: none) {
    height: auto;

    &:hover .front {
      transform: rotateY(0deg);
    }

    .front, .back {
      height: auto;
      position: relative;
    }

    .back {
      transform: rotateY(0deg);
      background-color: #fff;
      margin-top: -5px;
    }
  
  }
`

export default function({ front, back, transition=1, className }) {
  const bothStyles = {
    transition: `all ${transition}s`
  }

  return (
    <Flipper className={className} >
      <div style={bothStyles} className="front">
        {front}
      </div>
      <div style={bothStyles} className="back">
        {back}
      </div>
    </Flipper>
  )
}
import React from 'react'
import { useStaticQuery, graphql } from "gatsby"
import styled from 'styled-components'

import Button from '../../utils/button';

import gsap from 'gsap';

const Section = styled.section`
  background-image: linear-gradient(rgba(0, 0, 0, 0.65), rgba(0, 0, 0, 0.65)), url(${props => props.image.src});
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  height: 100vh;
`
const Wrapper = styled.div`
  color: #ddd;
  position: absolute;
  left: 50%;
  top: 50%;   // changed from 50
  transform: translate(-50%, -50%);
  width: ${props => props.theme.maxWidth};

  @media ${props => props.theme.breakpoints.down('lg')} {
    width: 100%;
    padding: 0 10%;
  }
`
const Title = styled.h1`
  margin-bottom: 20px;
  /* white-space: pre; */
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 300;
  font-size: 36px;

  @media ${props => props.theme.breakpoints.down('lg')} {
    font-size: 32px;
  }
`

const Show = styled(Button)`
  text-transform: uppercase;
  padding: 1rem 3rem;
  margin-top: 1rem;
  font-size: 1.6rem;

  @media ${props => props.theme.breakpoints.down('md')} {
    padding: 2rem 3rem;
    margin-top: 4rem;
    font-size: 1.8rem;
  }
`

export default function Hero() {
  const data = useStaticQuery(graphql`
    query HeroQuery {
      strapiHero {
        Heading1
        Heading2
        HeroImage {
          childImageSharp {
            fluid(maxWidth: 3000, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  `)
  const {Heading1, Heading2, HeroImage} = data.strapiHero

  const click = () => {
    gsap.to(window, {duration: 1, scrollTo: {y: ".features", offsetY: 200}});
  }

  return (
    <Section className="top" image={HeroImage.childImageSharp.fluid}>
      <Wrapper className="hero-title">
        <Title>
          {Heading1} <br />
          {Heading2}
        </Title>
        <Show outlined br="5px" click={click}>Show me more</Show>
      </Wrapper>
    </Section>
  )
}

import React from 'react'
import {useStaticQuery, graphql} from 'gatsby'
import Section from '../../utils/section'
import styled from 'styled-components'
import RowWrapper from '../../utils/rowWrapper'
import { Grid } from '@material-ui/core';


const Header = styled.h6`
  margin-bottom: 15px;
`

const Content = styled.p`
  line-height: 145%;
  text-align: justify;

  @media ${props => props.theme.breakpoints.down('md')} {
    font-size: 85%;
  }
  // padding: 1rem 1.5rem;
`

const IconWrapper = styled.div`
  svg {
    fill: #2C2C2C;
    font-size: 300%;
    display: block;
    margin-bottom: 10px;
    width: 1em;
    height: 1em;
  }
`

const FeatureRow = styled(RowWrapper)`
  margin: 0 2rem 2rem 2rem;
  border-radius: 2px;
  padding: 25px;
  /* background-color: #f8f8f8;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.15); */
  min-height: 32rem;

  @media ${props => props.theme.breakpoints.down('md')} {
   border-radius: 0;
   box-shadow: none;
   background-color: inherit;
  }
`

const Feature = ({title, content, Icon}) => (
  <FeatureRow className="featureCard" centered>
    <IconWrapper dangerouslySetInnerHTML={{__html: Icon}} />
    <Header>{title}</Header>
    <Content>{content}</Content>
  </FeatureRow>
)


export default function Features() {

  const data = useStaticQuery(graphql`
    query FeaturesQuery {
      strapiFeatureHeader {
        title
        content
      }
      allStrapiFeature {
        edges {
          node {
            content
            icon
            title
          }
        }
      }
    }
  `)


  const {title, content} = data.strapiFeatureHeader
  const { edges } = data.allStrapiFeature

  return (
    <div className="features">
    <Section
      header={title}
      // description={content}
    >
      <RowWrapper centered>
        <Grid justify="center" container>
            {edges.map(({node}) => 
              <Grid md={4} xs={12} item key={node.title}>
                <Feature title={node.title} content={node.content} Icon={node.icon} />
              </Grid>
            )}
        </Grid>
      </RowWrapper>
    </Section>
    </div>
  )
}

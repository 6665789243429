import React, { useState, useEffect } from 'react';
import {useStaticQuery, graphql} from 'gatsby'
import loadable from '@loadable/component'
// import Carousel from 'react-spring-3d-carousel';

import Section from '../../utils/section';
import TestimonialCard from '../../utils/testimonialCard';

import styled from 'styled-components';

import BgTestimonial from "../../images/bg-testimonial.png"

const Carousel = loadable(() => import('react-spring-3d-carousel'))

const TestimonialSection = styled(Section)`
  background: linear-gradient(rgba(0,0,0,0.04),rgba(0,0,0,0.04)), url(${BgTestimonial});
`


const CarouselContainer = styled.div`
  /* max-width: 960px; */
  width: 100rem;
  margin: -80px auto 3rem auto;
  height: 350px;
  /* margin-top: -80px; */
  /* padding: 2rem; */

  @media ${props => props.theme.breakpoints.down('md')} {
    width: 60rem;
  }

  @media ${props => props.theme.breakpoints.down('xs')} {
    width: 42rem;
  }
`

const Controls = styled.div`
  display: flex;
  width: 140px;
  justify-content: space-evenly;
  margin: 0 auto;
  margin-bottom: 15px;
  margin-top: 2rem;

  .dot {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: #fff;
    cursor: pointer;

    &.active {
      background-color: ${props => props.theme.colors.primary};
    }
  }
`

export default function Testimonials() {
  const [slideIndex, setSlideIndex] = useState(0);

  const data = useStaticQuery(graphql`
    query TestimonialQuery {
      strapiTestimonialHeader {
        title
      }
      allStrapiTestimonial {
        edges {
          node {
            content
            signature
          }
        }
      }
    }
  `);

  const {title} = data.strapiTestimonialHeader
  const { edges } = data.allStrapiTestimonial

  const slides = [];

  edges.map(({node}, i) => {
    slides.push({
      key: i + 1,
      content: <TestimonialCard 
      key = {i + 1}
      text={node.content}
      signature={node.signature}
    />
    })
  })

  useEffect(() => {
    const timer = setTimeout(() => slideIndex < slides.length - 1 ? setSlideIndex(slideIndex + 1) : setSlideIndex(0), 5000);
    return () => {
      clearTimeout(timer);
    };
  }, [slideIndex]);

  return (
    <TestimonialSection
      header={title}
      description=""
    >
      <CarouselContainer>
        <Carousel 
          slides={slides}
          goToSlide={slideIndex}
          offsetRadius={1}
          showNavigation={false}
        />
        <Controls>
          {slides.map((_, i) => <div key={i} className={["dot", i === slideIndex ? "active" : ''].join(' ')} onClick={() => setSlideIndex(i)} />)}
        </Controls>
      </CarouselContainer>
    </TestimonialSection>
  )
}
import React from 'react';

import styled from 'styled-components'

const Button = styled.button`
  display: inline-block;
  padding: 10px 30px;
  border: 1px solid ${props => props.theme.colors.primary};
  background-color: ${props => props.outlined ? "transparent" : props.theme.colors.primary};
  border-radius: ${props => props.br ? props.br : '200px'};
  transition: all 0.2s;
  color: #fff;
  cursor: pointer;

  &:active, &:hover {
  background-color:  ${props => props.theme.colors.primaryDark};
  border-color: ${props => props.outlined ? props.theme.colors.primary : props.theme.colors.primaryDark};
}
`

export default function({ children, outlined=false, br, style, click, className}) {
  return (
    <Button style={style} br={br} outlined={outlined} 
    className={className} onClick={click}>
      { children }
    </Button>
  )
}
